var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{attrs:{"headings":_vm.tableHeadings,"items":_vm.items},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAt,_vm.dateFormatConfig))+" - "+_vm._s(_vm._f("formatTime")(item.createdAt,_vm.timeFormatConfig))+" ")]}},{key:"amountInclVat",fn:function(ref){
var item = ref.item;
return [_vm._v(" kr. "+_vm._s(_vm._f("formaNumber")(item.amountInclVat,2))+" ")]}},{key:"vat",fn:function(ref){
var item = ref.item;
return [_vm._v(" kr. "+_vm._s(_vm._f("formaNumber")(item.vat,2))+" ")]}},{key:"fulfilledAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatus(item.fulfilledAt))+" ")]}},{key:"postedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.postedAt ? (item.postedAt | _vm.formatDate(_vm.dateFormatConfig)) : '-')+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }